import phone from "phone";
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validateFields = (object) => {
  const errors = [];
  for (let value in object) {
    if (object[value]?.trim() === "") {
      errors.push(value);
      continue;
    }
    if (value === "email") {
      const isEmail = validateEmail(object[value]?.toLowerCase());
      if (!isEmail) {
        errors.push({ field: "email", message: "Kindly input a valid email" });
        continue;
      }
    }
    if (value.includes("duration") || value.includes("attendants")) {
      if (Number(object[value]) <= 0) {
        errors.push({ field: value, message: `Kindly input a valid value` });
        continue;
      }
    }
    if (value === "contact_number") {
      // const result = validatePhoneNumberSync(object[value]);
      const result = phone(object[value], { country: object?.country });
      if (result?.isValid === false) {
        errors.push({
          field: value,
          message:
            result?.errors?.length > 1
              ? result?.errors[1]
              : "Kindly add a valid Contact Number",
        });
        continue;
      }
    }
    if (value === "message") {
      if (object[value]?.length > 750) {
        errors.push({
          field: value,
          message: `Message must not be more than 750 characters`,
        });
        continue;
      }
    }
    if (value.includes("attendants")) {
      if (Number(object[value]?.length) > 100) {
        errors.push({
          field: value,
          message: `Message must not be more than 100`,
        });
        continue;
      }
    }
    if (value === "duration") {
      if (Number(object[value]?.length) > 365) {
        errors.push({
          field: value,
          message: `Message must not be more than 365`,
        });
        continue;
      }
    }
    if (value.includes("first") || value.includes("last")) {
      if (object[value]?.length > 25) {
        errors.push({
          field: value,
          message: `Message must not be more than 25 characters`,
        });
        continue;
      }
    }
  }
  return errors;
};

export const resetErrors = (object, exceptionArray, cb) => {
  for (let value in object) {
    if (value in exceptionArray) continue;
    object[value] = "";
  }
  cb({ ...object });
};

export function capitalize(string) {
  var firstLetter = string.slice(0, 1);
  return firstLetter.toUpperCase() + string.substring(1);
}

export const setErrorField = (errors, object, cb) => {
  for (let value of errors) {
    if (typeof value === "object") {
      object[`${value?.field}Error`] = value?.message;
      continue;
    }
    if (value.includes("_")) {
      object[`${value}Error`] = capitalize(
        `${value.split("_").join(" ")} is required`
      );
      continue;
    }
    object[`${value}Error`] = capitalize(value + " is required");
  }
  cb({ ...object });
};
