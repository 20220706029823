import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import cont2 from "../assets/cont2.png";
import Footer from "../components/Footer";
import { addDoc, collection } from "firebase/firestore";
import { database } from "../firebaseConfig";
import {
  resetErrors,
  setErrorField,
  validateFields,
} from "../utils/validation";
import useUserCountry from "../hooks/useCountry";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

const Contact = () => {
  const { userCountry } = useUserCountry();
  const postRef = collection(database, "posts");
  const [loading, setLoading] = useState(false);
  const [formdata, setFormData] = useState({
    firstname: "",
    lastname: "",
    message: "",
    contact_number: "",
    email: "",
    country: "",
  });
  const [formDataErrors, setFormDataErrors] = useState({
    firstnameError: "",
    lastnameError: "",
    emailError: "",
    contact_numberError: "",
    messageError: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    return setFormData({
      ...formdata,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    resetErrors(formDataErrors, [], setFormDataErrors);
    const errorList = validateFields(formdata);
    if (errorList.length > 0) {
      return setErrorField(errorList, formDataErrors, setFormDataErrors);
    }
    setLoading(true);
    try {
      await addDoc(postRef, formdata);
      toast.success(
        <>
          <h2 className="text-sm font-semibold">Thanks for reaching us!</h2>
          <p className="text-xs">We will get back to you shortly.</p>
        </>,
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      resetErrors(formdata, [], setFormData);
      return setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went Wrong, Kindly Retry", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return setLoading(false);
    }
  };
  useEffect(() => {
    setFormData((prev) => ({ ...prev, country: userCountry }));
  }, [userCountry]);
  return (
    <>
      <Header />
      {/* hero */}
      <div className="section-center">
        <div className="space-y-8 md:flex  md:flex-row-reverse md:space-y-0 md:justify-between md:items-center md:gap-6 lg:gap-8">
          {/* image */}
          <div className="max-w-xs mx-auto lg:max-w-sm xl:max-w-md  md:w-1/2">
            <img src={cont2} alt="" className="w-full mx-auto" />
          </div>
          {/* text */}
          <div className="space-y-4 md:w-1/2">
            {/* text */}
            <div className="mx-auto text-center space-y-4 md:text-left md:mx-0">
              <h1 className="font-alata font-bold text-3xl lg:text-4xl xl:text-5xl text-[#3C4390] max-w-sm lg:max-w-md text-center mx-auto md:mx-0 md:text-left">
                We are always available 24/7 to attend to all your inquiries
              </h1>
              <h5 className="w-72 mx-auto text-center text-[#1C1F37] font-alata text-md md:text-left md:mx-0 xl:w-4/5 lg:w-80 xl:text-xl">
                Kindly fill out the inqury form below and our customer support
                staff will definetely get back to you in little or no time
              </h5>
            </div>
          </div>
        </div>
      </div>
      {/* form */}
      <div className="section-center">
        <div className="mt-8 md:mt-16 xl:mt-10 xl:w-3/4 xl:mx-auto">
          <form
            onSubmit={handleSubmit}
            className=" space-y-4 mx-auto lg:space-y-8 "
          >
            {/* first container */}
            <div className="lg:flex justify-between items-center lg:space-x-10 lg:space-y-0 space-y-4">
              {/* first name */}
              <div className="flex flex-col space-y-2 lg:w-1/2 ">
                <label htmlFor="firstname" className="font-alata text-lg">
                  First Name
                </label>
                <input
                  type="text"
                  value={formdata?.firstname}
                  onChange={handleChange}
                  name="firstname"
                  placeholder="Please Input your First Name"
                  className={`font-alata ${
                    formDataErrors?.firstnameError &&
                    "ring-2 rounded-lg ring-red-600"
                  } border px-2 py-2 text-sm max-w-2xl outline-blue`}
                />
                {formDataErrors.firstnameError && (
                  <span className="text-sm font-medium text-red-600">
                    {formDataErrors?.firstnameError}
                  </span>
                )}
              </div>
              {/* last name */}
              <div className="flex flex-col space-y-2 lg:w-1/2 ">
                <label htmlFor="firstname" className="font-alata text-lg">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastname"
                  value={formdata?.lastname}
                  placeholder="Please Input your Last Name"
                  onChange={handleChange}
                  className={`font-alata ${
                    formDataErrors?.lastnameError &&
                    "ring-2 rounded-lg ring-red-600"
                  } border px-2 py-2 text-sm max-w-2xl outline-blue`}
                />
                {formDataErrors.lastnameError && (
                  <span className="text-sm font-medium text-red-600">
                    {formDataErrors?.lastnameError}
                  </span>
                )}
              </div>
            </div>
            {/* second-container */}
            <div className="lg:flex justify-between items-center lg:space-x-10 lg:space-y-0 space-y-4">
              {/* Email */}
              <div className="flex flex-col space-y-2 lg:w-1/2 ">
                <label htmlFor="email" className="font-alata text-lg">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  value={formdata?.email}
                  onChange={handleChange}
                  placeholder="Please Input your Email Address here"
                  className={`font-alata ${
                    formDataErrors?.emailError &&
                    "ring-2 rounded-lg ring-red-600"
                  } border px-2 py-2 text-sm max-w-2xl outline-blue`}
                />
                {formDataErrors.emailError && (
                  <span className="text-sm font-medium text-red-600">
                    {formDataErrors?.emailError}
                  </span>
                )}
              </div>
              {/* Contact number */}
              <div className="flex flex-col space-y-2 lg:w-1/2  ">
                <label htmlFor="Contact Number" className="font-alata text-lg">
                  Contact Number
                </label>
                <input
                  type="Number"
                  name="contact_number"
                  value={formdata?.contact_number}
                  onChange={handleChange}
                  placeholder="Please Input your Contact Number here"
                  className={`font-alata ${
                    formDataErrors?.contact_numberError &&
                    "ring-2 rounded-lg ring-red-600"
                  } border px-2 py-2 text-sm max-w-2xl outline-blue`}
                />
                {formDataErrors.contact_numberError && (
                  <span className="text-sm font-medium text-red-600">
                    {formDataErrors?.contact_numberError}
                  </span>
                )}
              </div>
            </div>

            {/* message */}
            <div className="flex flex-col space-y-2 ">
              <label htmlFor="message" className="font-alata text-lg">
                Message
              </label>
              <textarea
                type="text"
                name="message"
                value={formdata?.message}
                onChange={handleChange}
                placeholder="Input message here"
                className={`font-alata ${
                  formDataErrors?.messageError &&
                  "ring-2 rounded-lg ring-red-600"
                } border px-2 py-2 text-sm w-full outline-blue h-32 resize-none`}
              />
              {formDataErrors.messageError && (
                <span className="text-sm font-medium text-red-600">
                  {formDataErrors?.messageError}
                </span>
              )}
            </div>
            {/* submit button */}
            <button
              type="submit"
              className="mx-auto w-32 text-center px-6 rounded-md shadow-md text-white py-2 bg-blue font-sans flex justify-center"
            >
              {loading ? <FaSpinner className="animate-spin" /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
