import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import folio1 from '../assets/folio1.png'
import pot1 from '../assets/pot1.jpg'
import pot2 from '../assets/pot2.jpg'
import pot3 from '../assets/pot3.jpg'
import pot4 from '../assets/pot4.jpg'
import pot11 from '../assets/pot11.jpg'
import pot22 from '../assets/pot22.jpg'
import pot33 from '../assets/pot33.jpg'
import pot44 from '../assets/pot44.jpg'

import Footer from '../components/Footer'

const Portfolio = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <div className='section-center'>
        {/* cont */}
        <div className='flex flex-col justify-center items-center md:flex md:flex-row-reverse md:justify-between md:gap-8  xl:gap-4'>
          {/* image */}
          <div className='max-w-xs sm:max-w-sm md:w-1/2 lg:max-w-full   '>
            <img
              src={folio1}
              alt=''
              className='w-64 sm:w-72 md:w-80 mx-auto xl:w-4/5'
            />
          </div>
          {/* text */}
          <div className='max-w-xs sm:max-w-sm md:w-1/2 lg:max-w-md xl:max-w-xl'>
            <h3 className='text-xl mt-10 sm:text-2xl md:text-left md:text-2xl lg:text-3xl md:max-w-xs lg:max-w-sm  xl:max-w-md xl:text-4xl 2xl:text-5xl '>
              We are highly trusted by clients to handle their projects.Feel
              free to browse through our portfolio.Got any questions ?
            </h3>
            {/* button */}
            <Link to='/contact'>
              <button className="bg-blue text-white px-4 py-2 rounded-md mx-auto flex justify-center md:mx-auto md:px-4 md:py-2 lg:px-5 mt-8 sm:mt-10 lg:mt-10">
                Contact us
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* our projects */}
      <div className='section-center'>
        {/* header */}
        <div className='text-center'>
          <p className='text-[#5E6282] sm:text-xl lg:text-2xl'>Our Projects</p>
          <h3 className='mt-2 text-2xl sm:text-3xl lg:text-4xl'>
            Excellence Guarranteed
          </h3>
        </div>
        {/* body */}
        <div>
          {/* <!-- Creations Container --> */}
          <div class='container max-w-6xl mx-auto my-16 px-4 text-gray-900 md:px-0'>
            {/* <!-- Items Container --> */}
            <div class='item-container'>
              {/* <!-- Item 1 --> */}
              <div class='group item '>
                <a
                  href='https://www.founders4schools.org.uk/'
                  target='blank'
                  className='cursor-pointer'
                >
                  {/* <!-- Desktop Image --> */}
                  <img
                    src={pot11}
                    alt=''
                    class='hidden w-full duration-200 md:block group-hover:scale-110'
                  />
                  {/* <!-- Mobile Image --> */}
                  <img src={pot1} alt='' class='w-full md:hidden' />
                  {/* <!-- Item Gradient --> */}
                  <div class='item-gradient'></div>
                  {/* <!-- Item Text --> */}
                  <h4>Founders4School</h4>
                </a>
              </div>

              {/* <!-- Item 2 --> */}
              <div class='group item'>
                <a
                  href='https://honest-properties-co.realtornigeria.com/'
                  target='blank'
                  className='cursor-pointer'
                >
                  {/* <!-- Desktop Image --> */}
                  <img
                    src={pot22}
                    alt=''
                    class='hidden w-full duration-200 md:block group-hover:scale-110'
                  />
                  {/* <!-- Mobile Image --> */}
                  <img src={pot2} alt='' class='w-full md:hidden' />
                  {/* <!-- Item Gradient --> */}
                  <div class='item-gradient'></div>
                  {/* <!-- Item Text --> */}
                  <h4>Honest Properties</h4>
                </a>
              </div>

              {/* <!-- Item 3 --> */}
              <div class='group item'>
                <a
                  href='https://www.digitalboost.org.uk'
                  target='blank'
                  className='cursor-pointer'
                >
                  {/* <!-- Desktop Image --> */}
                  <img
                    src={pot33}
                    alt=''
                    class='hidden w-full duration-200 md:block group-hover:scale-110'
                  />
                  {/* <!-- Mobile Image --> */}
                  <img src={pot3} alt='' class='w-full md:hidden' />
                  {/* <!-- Item Gradient --> */}
                  <div class='item-gradient'></div>
                  {/* <!-- Item Text --> */}
                  <h4>Digital Boost</h4>
                </a>
              </div>

              {/* <!-- Item 4 --> */}
              <div class='group item'>
                {/* <!-- Desktop Image --> */}
                <img
                  src={pot44}
                  alt=''
                  class='hidden w-full duration-200 md:block group-hover:scale-110'
                />
                {/* <!-- Mobile Image --> */}
                <img src={pot4} alt='' class='w-full md:hidden' />
                {/* <!-- Item Gradient --> */}
                <div class='item-gradient'></div>
                {/* <!-- Item Text --> */}
                <h4>Evertore</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <Footer />
    </>
  )
}
export default Portfolio
