import React from "react";
import logo2 from "../assets/logo2.png";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

const Footer = () => {
  return (
    <div className="bg-veryLightGray px-4 py-10 lg:py-16">
      {/* container */}
      <div className="flex flex-col items-center justify-center text-white font-sans space-y-8  md:flex-row md:items-start md:space-y-0 md:justify-around md:gap-4 lg:justify-around ">
        {/* logo and intro */}
        <div className="space-y-3 lg:space-y-5 ">
          {/* logo */}
          <div className="">
            <img src={logo2} alt="" className="w-3/4 mx-auto md:mx-0" />
          </div>
          {/* intro */}
          <div className="max-w-xs md:w-72">
            <p className="text-center text-sm mx-auto font-normal md:text-left">
              Your one stop platform for all your IT software solutions .
              Contact us today and lets take your idea to the online
              representation it deserves
            </p>
          </div>
        </div>
        {/* links */}
        <div className=" hidden xl:block space-y-3 lg:space-y-5">
          <h1 className="font-sans text-left text-2xl  ">Links</h1>
          <div className="flex flex-col space-y-2 text-sm">
            <Link to="/about">About Us</Link>
            <Link to="/portfolio">Portfolio</Link>
            <Link to="/career">Career</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
        {/* Head Office */}
        <div className="max-w-xs text-center md:text-left space-y-3 lg:space-y-5">
          <h1 className="font-sans text-2xl  ">Head Office</h1>
          <div className="space-y-2 lg:space-y-4">
            <p className="text-sm">
              Plot 1013,Cadastral Zone <br /> B05, Jabi, Abuja
            </p>
            <p className="text-sm">
              contact@dimechest.com <br /> +234 (0) 906 371 1112
            </p>
          </div>
        </div>
        {/* office hours */}
        <div className="max-w-xs text-center md:text-left space-y-3 lg:space-y-5 ">
          <h1 className="font-sans text-2xl  "> Office Hours</h1>
          <div className="space-y-2 lg:space-y-4">
            <p className="text-sm">
              Mon-Sat : 8:00-17:00
              <br /> Sun: Closed
            </p>
            {/* limks */}
            <div className="flex justify-between items-center md:justify-start md:gap-4">
              <Link href="#" className="lg:text-2xl">
                <FaFacebook />
              </Link>
              <a
                href="https://instagram.com/dimechest?igshid=YmMyMTA2M2Y="
                target="blank"
                className="lg:text-2xl"
              >
                <GrInstagram />
              </a>
              <Link href="#" className="lg:text-2xl">
                <FaTwitter />
              </Link>
              <a
                href="https://www.linkedin.com/company/dimechest"
                target="blank"
                className="lg:text-2xl"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
