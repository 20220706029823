import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import apple from "../assets/apple.png";
import google from "../assets/google.png";
import slack from "../assets/slack.png";
import spotify from "../assets/spotify.png";
import about1 from "../assets/about1.png";
import about2 from "../assets/about2.png";
import about3 from "../assets/about3.png";
import about4 from "../assets/about4.png";
import port1 from "../assets/port1.png";
import port2 from "../assets/port2.png";
import port3 from "../assets/port3.png";
import port4 from "../assets/port4.png";
import cont1 from "../assets/cont1.png";
import Footer from "../components/Footer";

import { MdOutlineDesignServices } from "react-icons/md";
import { MdOutlineComputer } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import { MdManageAccounts } from "react-icons/md";

const Home = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <div className="relative">
        <div className="back-image  h-96 sm:h-[26em] md:h-[30rem] lg:h-[36rem] 2xl:h-[38rem] flex flex-col justify-center items-center text-white space-y-6 sm:space-y-8 px-2">
          <h1 className="text-3xl font-mono max-w-xs text-center sm:max-w-sm sm:text-4xl md:text-5xl md:max-w-lg lg:text-6xl lg:max-w-3xl  tracking-wide">
            Let’s help bring your ideas to reality.
          </h1>
          <h1 className="font-sans font-normal text-center max-w-xs sm:max-w-xs md:text-lg md:max-w-lg lg:text-xl lg:max-w-xl">
            Do you have a business idea that needs an online presence? we have
            got you covered. Lets help you build the digital platform that
            matches your idea
          </h1>
          {/* call to action */}
          <div className="flex justify-center items-center gap-6">
            <Link to="/career">
              <button className="bg-white text-blue px-4 py-2 rounded-sm shadow font-semibold sm:px-6 sm:rounded-md md:py-3 md:text-base lg:px-10 lg:text-lg lg:font-bold font-sans">
                Join us
              </button>
            </Link>
            <Link to="/contact">
              <button className="bg-blue text-white px-4 py-2 rounded-sm shadow font-semibold sm:px-6 sm:rounded-md md:py-3 md:text-base lg:px-10 lg:text-lg lg:font-bold font-sans">
                Contact us
              </button>
            </Link>
          </div>
        </div>
        {/* who we work with */}
        <div className="bg-white shadow shadow-veryLightGray rounded-md flex justify-around  px-2 py-6 max-w-xs mx-auto items-center absolute left-0 right-0 -bottom-14 sm:max-w-sm sm:-bottom-16 md:max-w-lg md:py-6 md:px-2 lg:max-w-xl xl:max-w-2xl lg:-bottom-16 xl:-bottom-20  ">
          <img src={apple} alt="" className="w-1/6" />
          <img src={slack} alt="" className=" w-1/6" />
          <img src={spotify} alt="" className="w-1/6" />
          <img src={google} alt="" className="w-1/6 " />
        </div>
      </div>
      {/* about us */}
      <div className="section-center md:flex md:flex-row-reverse justify-between items-center gap-2 lg:gap-4 ">
        {/* images */}
        <div className="pt-10 xl:pt-20 flex flex-col justify-end mx-auto space-y-4 md:w-1/2 md:flex-row md:space-y-0 md:gap-0 xl:w-2/4">
          {/* first */}
          <div className="flex md:flex-col justify-center items-center gap-2 mx-auto max-w-xs sm:max-w-sm sm:gap-3 md:gap-6">
            <img src={about1} alt="" className="w-1/4 md:w-3/4" />
            <img src={about2} alt="" className="w-1/4 md:w-3/4" />
            <img src={about3} alt="" className="w-1/4 md:w-3/4" />
          </div>
          {/* second */}
          <div className=" flex justify-center mx-auto  ">
            <img src={about4} alt="" className="w-3/4 md:w-5/6 xl:w-5/6" />
          </div>
        </div>
        {/* text */}
        <div className="space-y-4 md:w-1/2">
          {/* heading */}
          <div>
            <h3 className="md:text-left md:text-2xl lg:text-4xl xl:text-5xl">
              About us
            </h3>
            <div className="border-b-2 mt-2 w-20 mx-auto md:mx-0 md:w-28 md:border-b-3 border-paleRed"></div>
          </div>
          {/* body */}
          <div>
            <h5 className="md:text-left md:text-sm md:max-w-xs lg:text-sm lg:max-w-sm lg:mx-0 xl:text-base xl:max-w-md">
              DimeChest is an IT company providing IT services and solutions for
              numerous clients in various industries. We have over 100
              compentent software engineers with vast number of years of
              experience in the IT sector. We provide services ranging from
              software design,development ,delopyment and management. Wanna know
              more about us , Then click the button below
            </h5>
          </div>

          {/* button */}
          <div>
            <Link to="/about">
              <button className="bg-blue text-white px-4 py-2 rounded-md mx-auto flex justify-center md:mx-0 md:px-3 md:py-2 lg:px-5 ">
                See More
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* our services */}
      <div className="section-center">
        {/* title */}
        <div>
          <div>
            <h3>Our Services</h3>
            <div className="border-b-2 mt-2 w-20 mx-auto  md:w-28 md:border-b-3 border-paleRed"></div>
          </div>
          <h5>
            We offer a wide range of services some of which include the
            following services
          </h5>
        </div>
        {/* content */}
        <div className="mt-10 flex flex-col justify-center items-center space-y-8 md:grid md:grid-cols-2 md:space-y-0 md:gap-6 md:mx-auto md:justify-center md:items-center lg:max-w-xl xl:max-w-full xl:grid-cols-4 xl:mt-18 md:mt-16 ">
          {/* content 1 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 xl:px-6 hover:-translate-y-8 duration-1000 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <MdOutlineDesignServices className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Product <br /> Design
            </h1>
            <p className="text-[#757095] font-sans">over 50 designs created</p>
          </div>
          {/* content 2 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <MdOutlineComputer className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Web <br /> Development
            </h1>
            <p className="text-[#757095] font-sans">
              over 50 web application deployed
            </p>
          </div>
          {/* content 3 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <BiMobile className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Mobile App <br /> Development
            </h1>
            <p className="text-[#757095] font-sans">
              Over 17 mobile Apps deployed
            </p>
          </div>
          {/* content 4 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6  h-64">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <MdManageAccounts className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Product <br /> Management
            </h1>
            <p className="text-[#757095] font-sans">
              Over 5 products in mangement
            </p>
          </div>
        </div>
      </div>
      {/* Our Portfolio */}
      <div className="section-center md:flex md:flex-row justify-between items-center gap-2 lg:gap-4 xl:gap-16 ">
        {/* images */}
        <div className="pt-10 xl:pt-20 flex flex-col justify-end mx-auto space-y-4 md:w-1/2 md:flex-row-reverse md:space-y-0 md:gap-0 xl:w-2/4">
          {/* first */}
          <div className="flex md:flex-col justify-center items-center gap-2 mx-auto max-w-xs sm:max-w-sm sm:gap-3 md:gap-6">
            <img src={port1} alt="" className="w-1/4 md:w-3/4" />
            <img src={port2} alt="" className="w-1/4 md:w-3/4" />
            <img src={port3} alt="" className="w-1/4 md:w-3/4" />
          </div>
          {/* second */}
          <div className=" flex justify-center mx-auto  ">
            <img src={port4} alt="" className="w-3/4 md:w-5/6 xl:w-5/6" />
          </div>
        </div>
        {/* text */}
        <div className="space-y-4 md:w-1/2">
          {/* heading */}
          <div>
            <h3 className="md:text-left md:text-2xl lg:text-4xl xl:text-5xl">
              Our Portfolio
            </h3>
            <div className="border-b-2 mt-2 w-20 mx-auto md:mx-0 md:w-28 md:border-b-3 border-paleRed"></div>
          </div>
          {/* body */}
          <div>
            <h5 className="md:text-left md:text-sm md:max-w-xs lg:text-sm lg:max-w-sm lg:mx-0 xl:text-base xl:max-w-md">
              We have handled many exclusive projects for numerous clients
              scattered all over the world ranging from E-commerce projects to
              administrative projects to Landing pages. Our Clients can tesify
              to the quality of our deliveries and have called on us to work for
              them on different other projects whilst making referals to their
              friends and family. Click the link below to see projects we have
              excuted succssfully.
            </h5>
          </div>

          {/* button */}
          <div>
            <Link to="/portfolio">
              <button className="bg-blue text-white px-4 py-2 rounded-md mx-auto flex justify-center md:mx-0 md:px-3 md:py-2 lg:px-5 ">
                See More
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* get in touch */}
      <div className="mt-16 md:mt-32 xl:mt-40 mb-16 max-w-sm sm:max-w-md mx-auto font-sans md:max-w-2xl lg:max-w-5xl xl:max-w-6xl px-4 md:px-6  lg:px-8   space-y-6 md:flex flex-row-reverse justify-center gap-4 items-center md:space-y-0 ">
        {/* image */}
        <div className="max-w-xs mx-auto md:max-w-sm md:w-1/2 xl:max-w-md">
          <img src={cont1} alt="" />
        </div>
        {/* text */}
        <div className="space-y-4 md:w-1/2 lg:space-y-6 xl:space-y-6">
          <p className="font-sans text-[#5E6282] md:text-left text-center lg:text-xl">
            Ready to start your project ?
          </p>
          <h3 className="mt-0 text-2xl text-center mx-auto max-w-xs sm:text-center sm:mx-auto sm:text-3xl md:mx-0 sm:max-w-sm md:text-left md:text-3xl lg:text-4xl lg:max-w-lg xl:text-6xl font-sans">
            Then lets help you bring those ideas to reality
          </h3>
          {/* button */}
          <div>
            <Link to="/contact">
              <button className="bg-blue text-white px-4 py-2 rounded-md mx-auto flex justify-center md:mx-0 md:px-3 md:py-2 lg:px-5 ">
                Contact us
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
