import React, { useState } from "react";
import logo from "../assets/logo.png";
import logo2 from "../assets/logo2.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [pannel, setPannel] = useState(false);

  return (
    <nav className=" relative mx-auto px-1 md:px-4 lg:px-10 py-2 md:py-2 bg-white shadow-md shadow-blue">
      {/* <!-- Flex Container For Nav Items --> */}
      <div className="flex items-center justify-between px-4 sm:px-6 md:px-0 my-6 ">
        {/* <!-- Logo --> */}
        <div className="z-30 ">
          {pannel ? (
            <Link to="/">
              <img src={logo2} alt="" id="logo" className="w-3/5" />
            </Link>
          ) : (
            <Link to="/">
              <img src={logo} alt="" id="logo" className="w-3/5 xl:w-full" />{" "}
            </Link>
          )}
        </div>

        {/* <!-- Menu Items --> */}
        <div className="hidden items-center font-sans  font-bold  space-x-10 lg:space-x-10 xl:space-x-20  text-grayishBlue md:flex justify-center normal-case">
          <Link
            to="/about"
            className="tracking-widest hover:text-softRed font-sans "
          >
            About Us
          </Link>
          <Link to="/portfolio" className="tracking-widest hover:text-softRed">
            Portfolio
          </Link>
          <Link to="/career" className="tracking-widest hover:text-softRed">
            Career
          </Link>

          <Link
            to="/contact"
            className="md:px-2 lg:px-4 py-2 text-white bg-[#3C4390] border-2 border-[#3C4390] rounded-md shadow-md hover:text-[#3C4390] hover:bg-white"
          >
            Get in Touch
          </Link>
        </div>
        {/* <!-- Hamburger Button --> */}
        {pannel ? (
          <button
            id="menu-btn"
            className="z-30 open block md:hidden focus:outline-none hamburger"
            onClick={() => setPannel(!pannel)}
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
        ) : (
          <button
            id="menu-btn"
            className="z-30 block md:hidden focus:outline-none hamburger"
            onClick={() => setPannel(!pannel)}
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
        )}
      </div>
      {/* <!-- Mobile Menu --> */}
      {pannel ? (
        <div
          id="menu"
          className="fixed inset-0 z-20  flex-col items-center self-end w-full h-full m-h-screen px-6 py-1 pt-32 pb-4 tracking-widest text-white uppercase divide-y divide-gray-500 opacity-90 bg-veryLightGray"
        >
          <div className="w-full py-3 text-center">
            <Link to="/about" className="block hover:text-softRed">
              About Us
            </Link>
          </div>
          <div className="w-full py-3 text-center">
            <Link to="/portfolio" className="block hover:text-softRed">
              Portfolio
            </Link>
          </div>
          <div className="w-full py-3 text-center">
            <Link to="/career" className="block hover:text-softRed">
              Career
            </Link>
          </div>
          <div className="w-full py-3 text-center">
            <Link to="/contact" className="block hover:text-softRed">
              Get in Touch
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </nav>
  );
};

export default Header;
