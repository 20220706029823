import React from "react";
import { useState } from "react";
import Header from "../components/Header";
import aboutp1 from "../assets/aboutp1.png";
import tech1 from "../assets/tech1.png";
import tech2 from "../assets/tech2.png";
import tech3 from "../assets/tech3.png";
import tech4 from "../assets/tech4.png";
import tech5 from "../assets/tech5.png";
import tech6 from "../assets/tech6.png";
import team1 from "../assets/team1.png";
import team2 from "../assets/team2.png";
import team3 from "../assets/team3.png";
import team4 from "../assets/team4.png";

import aboutp2 from "../assets/aboutp2.png";
import { Link } from "react-router-dom";
import { GiStumpRegrowth } from "react-icons/gi";
import { SiTrustpilot } from "react-icons/si";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { TiArrowMinimise } from "react-icons/ti";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Footer from "../components/Footer";

import value1 from "../assets/value1.png";

const About = () => {
  const [slide, setSlide] = useState(1);

  const handleforward = () => {
    if (slide < 4) {
      let value = slide + 1;
      setSlide(value);
    } else setSlide(1);
  };
  const handlebackward = () => {
    if (slide > 1) {
      let value = slide - 1;
      setSlide(value);
    } else setSlide(4);
  };
  const handleforward1 = () => {
    if (slide < 2) {
      let value = slide + 1;
      setSlide(value);
    } else setSlide(1);
  };
  const handlebackward1 = () => {
    if (slide > 1) {
      let value = slide - 1;
      setSlide(value);
    } else setSlide(2);
  };

  return (
    <>
      <Header />
      {/* hero */}
      <div className="section-center">
        {/* cont */}
        <div className="flex flex-col justify-center items-center md:flex md:flex-row-reverse md:justify-between md:gap-4">
          {/* image */}
          <div className="max-w-xs sm:max-w-sm md:w-1/2 lg:max-w-full ">
            <img src={aboutp1} alt="" className="md:w-full" />
          </div>
          {/* text */}
          <div className="max-w-xs sm:max-w-sm md:w-1/2 lg:max-w-full ">
            <h3 className="text-2xl mt-10 sm:text-3xl md:text-left md:text-2xl lg:text-4xl md:max-w-xs lg:max-w-sm  xl:max-w-md xl:text-5xl">
              We are a household IT company building online presences for all
              your business ideas
            </h3>
            {/* button */}
            <Link to="/contact">
              <button className="bg-blue text-white px-4 py-2 rounded-md mx-auto flex justify-center md:mx-auto md:px-4 md:py-2 lg:px-5 mt-8 sm:mt-10 lg:mt-10">
                Contact us
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* about us */}
      <div className="section-center space-y-8 md:space-y-16">
        {/* header */}
        <div className="text-center">
          <p className="text-[#5E6282] sm:text-xl lg:text-2xl">Our Story</p>
          <h3 className="mt-2 text-2xl sm:text-3xl lg:text-4xl">
            Brief History About US
          </h3>
        </div>
        {/* conten */}
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-center md:items-center  md:gap-8 lg:justify-center lg:gap-14 xl:gap-20">
          {/* image */}
          <div className="max-w-xs md:w-1/2 xl:max-w-sm ">
            <img src={aboutp2} alt="" className="w-64 sm:w-96 lg:w-full " />
          </div>
          {/* text */}
          <div className="text-center md:text-left space-y-3 md:w-1/2 lg:max-w-xs xl:max-w-sm xl:space-y-5">
            <h3 className="mt-16 md:mt-0 md:text-left xl:text-5xl">
              DimeChest
            </h3>
            <p className="text-base sm:text-base xl:text-lg  ">
              DimeChest is an IT company providing IT services and solutions for
              numerous clients in various industries. We have over 100
              compentent software engineers with vast number of years of
              experience in the IT sector. We provide services ranging from
              software design,development ,delopyment and management. Why not
              get in touch today
            </p>
          </div>
        </div>
      </div>
      {/* our key values */}
      <div className="section-center px-4 ">
        {/* conatiner */}
        <div className="xl:flex xl:justify-between xl:items-center xl:gap-16">
          {/* image */}
          <div className="w-72 mx-auto md:w-96 xl:w-1/3">
            <img src={value1} alt="" />
          </div>
          {/* text */}
          <div className="md:max-w-lg md:mx-auto lg:max-w-3xl xl:w-2/3 ">
            <h3 className="text-3xl md:text-4xl xl:text-left xl:text-4xl">
              Our key values
            </h3>
            {/* values options */}
            <div className="mt-16 space-y-12 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-8 ">
              {/* value1 */}
              <div>
                {/* icon */}
                <div className="bg-blue w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <SiTrustpilot className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Integrity
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    We discharge our duties with all due deligence,Entrusting
                    your projects into capable handles willing and able to
                    deliver your projects according to your specifications
                  </h5>
                </div>
              </div>
              {/* value2 */}
              <div>
                {/* icon */}
                <div className="bg-blue w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <GiStumpRegrowth className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Growth
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    We provide after deployment assistance thereby ensuring that
                    we stand shoulder to shoulder with you in incases where you
                    desire a new feature or a total upgrade of your project
                  </h5>
                </div>
              </div>
              {/* value3 */}
              <div>
                {/* icon */}
                <div className="bg-blue w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <VscWorkspaceTrusted className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Respect
                  </h3>
                  <h5 className="xl:text-left mt-1 ">
                    We respect the privacy of your product and idea, so you can
                    be rest assured that what ever project you share or build
                    with us is in safe hands and totaly covered by our privacy
                    protection policy
                  </h5>
                </div>
              </div>
              {/* value4 */}
              <div>
                {/* icon */}
                <div className="bg-blue w-12 h-12 mx-auto px-1 py-2 rounded-md xl:mx-0">
                  <TiArrowMinimise className="text-white text-3xl mx-auto" />
                </div>
                {/* text */}
                <div>
                  <h3 className="mt-2 text-xl xl:text-2xl xl:text-left">
                    Excellence
                  </h3>
                  <h5 className="xl:text-left mt-1">
                    The satisfaction of our customers is our paramount concern,
                    so we develop all projects with uptmost care and precision
                    in order to ensure that the required output meets your
                    desired expectations
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* technologies */}
      <div className="bg-veryLightGray flex flex-col justify-center items-center px-4">
        {/* div
        container */}
        <div className="section-center space-y-4 sm:space-y-8 xl:space-y-12 ">
          <h3 className="text-paleRed mt-0 text-xl sm:text-2xl lg:text-3xl">
            Technologies we use
          </h3>
          {/* image */}
          <div className="flex justify-around items-center gap-2 sm:gap-4 md:max-w-xl lg:max-w-2xl lg:gap-6 xl:max-w-3xl">
            <img src={tech1} alt="" className="w-1/6" />
            <img src={tech2} alt="" className="w-1/6" />
            <img src={tech3} alt="" className="w-1/6" />
            <img src={tech4} alt="" className="w-1/6" />
            <img src={tech5} alt="" className="w-1/6 " />
            <img src={tech6} alt="" className="w-1/6 " />
          </div>
        </div>
      </div>
      {/* our team */}
      <div className="section-center space-y-8 md:space-y-16">
        {/* header */}
        <div className="text-center">
          <p className="text-[#5E6282] sm:text-xl lg:text-2xl">Our Team</p>
          <h3 className="mt-2 text-2xl sm:text-3xl lg:text-4xl">
            Competence & Excellence
          </h3>
        </div>
        {/* images */}
        <div>
          {/* small slide */}
          <div className="flex justify-around items-center gap-8 md:hidden">
            {/* button left */}
            <div className=" cursor-pointer" onClick={() => handlebackward()}>
              <IoIosArrowBack className="text-4xl text-veryLightGray  " />
            </div>
            {/* no 1 */}
            <div>
              {/* member 1 */}
              {slide === 1 ? (
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team1} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">Razaq Audu</p>
                  <h3 className="mt-2 text-xl tracking-widest">CEO</h3>
                </div>
              ) : (
                ""
              )}
              {/* member 2 */}
              {slide === 2 ? (
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team2} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">
                    Ibrahim Audu
                  </p>
                  <h3 className="mt-2 text-xl tracking-widest">COO</h3>
                </div>
              ) : (
                ""
              )}
              {/* member 3 */}
              {slide === 3 ? (
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team3} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">
                    Charles Wahab
                  </p>
                  <h3 className="mt-2 text-xl ">Strategic Adviser</h3>
                </div>
              ) : (
                ""
              )}
              {/* member 4 */}
              {slide === 4 ? (
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team4} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">
                    Michell Okwu
                  </p>
                  <h3 className="mt-2 text-xl ">UI/UX Designer</h3>
                </div>
              ) : (
                " "
              )}
            </div>
            {/* button right */}
            <div className=" cursor-pointer" onClick={() => handleforward()}>
              <IoIosArrowForward className="text-4xl text-veryLightGray animate-pulse " />
            </div>
          </div>
          {/* medium slide */}
          <div className="hidden  justify-around items-center gap-8 md:flex xl:hidden">
            {/* button left */}
            <div className=" cursor-pointer" onClick={() => handlebackward1()}>
              <IoIosArrowBack className="text-4xl text-veryLightGray " />
            </div>
            {/* no 2 */}
            <div>
              {/* member 1 */}
              {slide === 1 ? (
                <div className="flex justify-center items-center gap-8 lg:gap-12">
                  <div>
                    <div className="flex justify-center items-center ">
                      <img src={team1} alt="" className="w-56" />
                    </div>
                    <p className="text-center mt-2 tracking-normal">
                      Razaq Audu
                    </p>
                    <h3 className="mt-2 text-xl tracking-widest">CEO</h3>
                  </div>
                  <div>
                    <div className="flex justify-center items-center ">
                      <img src={team2} alt="" className="w-56" />
                    </div>
                    <p className="text-center mt-2 tracking-normal">
                      Ibrahim Audu
                    </p>
                    <h3 className="mt-2 text-xl tracking-widest">COO</h3>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* member 2 */}
              {slide === 2 ? (
                <div className="flex justify-center items-center gap-4 lg:gap-8">
                  <div>
                    <div className="flex justify-center items-center ">
                      <img src={team3} alt="" className="w-56" />
                    </div>
                    <p className="text-center mt-2 tracking-normal">
                      Charles Wahab
                    </p>
                    <h3 className="mt-2 text-xl ">Strategic Adviser</h3>
                  </div>
                  <div>
                    <div className="flex justify-center items-center ">
                      <img src={team4} alt="" className="w-56" />
                    </div>
                    <p className="text-center mt-2 tracking-normal">
                      Michell Okwu
                    </p>
                    <h3 className="mt-2 text-xl ">UI/UX Designer</h3>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* button right */}
            <div className=" cursor-pointer" onClick={() => handleforward1()}>
              <IoIosArrowForward className="text-4xl text-veryLightGray animate-pulse " />
            </div>
          </div>
          {/* xl slide */}
          <div className="hidden  justify-around items-center gap-8 md:hidden xl:flex">
            {/* no 3 */}
            <div>
              {/* member 1 */}
              <div className="flex justify-center items-center gap-4 lg:gap-8 xl:gap-12">
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team1} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">Razaq Audu</p>
                  <h3 className="mt-4 text-xl tracking-widest">CEO</h3>
                </div>
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team2} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">
                    Ibrahim Audu
                  </p>
                  <h3 className="mt-2 text-xl tracking-widest">COO</h3>
                </div>
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team3} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">
                    Charles Wahab
                  </p>
                  <h3 className="mt-2 text-xl ">Strategic Adviser</h3>
                </div>
                <div>
                  <div className="flex justify-center items-center ">
                    <img src={team4} alt="" className="w-56" />
                  </div>
                  <p className="text-center mt-2 tracking-normal">
                    Michell Okwu
                  </p>
                  <h3 className="mt-4 text-xl ">UI/UX Designer</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Foot */}
      <Footer />
    </>
  );
};

export default About;
