import React from "react";
import Header from "../components/Header";
import { Link } from "react-scroll";
import car1 from "../assets/car1.png";
import car2 from "../assets/car2.png";
import { MdOutlineDesignServices } from "react-icons/md";
import { MdOutlineComputer } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import { MdManageAccounts } from "react-icons/md";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdOutlineSupportAgent } from "react-icons/md";
import { SiMarketo } from "react-icons/si";
import { GiGrowth } from "react-icons/gi";
import Footer from "../components/Footer";

const Career = () => {
  return (
    <>
      <Header />
      {/* hero */}
      <div className="section-center">
        {/* cont */}
        <div className="flex flex-col justify-center items-center md:flex md:flex-row-reverse md:justify-between md:gap-8  xl:gap-4">
          {/* image */}
          <div className="max-w-xs sm:max-w-sm md:w-1/2 lg:max-w-full   ">
            <img
              src={car1}
              alt=""
              className="w-56 sm:w-64 md:w-80 mx-auto xl:w-4/5"
            />
          </div>
          {/* text */}
          <div className="max-w-xs sm:max-w-sm md:w-1/2 lg:max-w-md xl:max-w-xl">
            <h3 className="text-xl  mt-10 sm:text-2xl md:text-left md:text-2xl lg:text-3xl md:max-w-xs lg:max-w-md  xl:max-w-lg xl:text-4xl  ">
              Find your dream job here. Are you extremely competent in software
              engineering?Then come work with a company that has your best
              interest at heart
            </h3>
            {/* button */}
            <Link
              activeClass="active"
              to="apply"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <button className="bg-blue text-white px-4 py-2 rounded-md mx-auto flex justify-center md:mx-auto md:px-4 md:py-2 lg:px-5 mt-8 sm:mt-10 lg:mt-10">
                Read up on the process
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* positions */}
      <div className="section-center">
        {/* header */}
        <div className="text-center">
          <p className="text-[#5E6282] sm:text-xl lg:text-2xl">Job Lisitings</p>
          <h3 className="mt-2 text-2xl sm:text-3xl lg:text-4xl">
            Available Positions
          </h3>
        </div>
        {/* content 1 */}
        <div className="mt-10 flex flex-col justify-center items-center space-y-8 md:grid md:grid-cols-2 md:space-y-0 md:gap-6 md:mx-auto md:justify-center md:items-center lg:max-w-xl xl:max-w-full xl:grid-cols-4 xl:mt-18 md:mt-16 ">
          {/* content 1 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 xl:px-6 hover:-translate-y-8 duration-1000 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <AiOutlineFundProjectionScreen className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Project <br /> Management
            </h1>
            <p className="text-[#757095] font-sans">2 slots available </p>
          </div>
          {/* content 2 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <MdOutlineComputer className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Web <br /> Development
            </h1>
            <p className="text-[#757095] font-sans">6 slots available</p>
          </div>
          {/* content 3 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <BiMobile className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Mobile App <br /> Development
            </h1>
            <p className="text-[#757095] font-sans">5 slots available</p>
          </div>
          {/* content 4 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6  h-64">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <MdManageAccounts className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Human Resource <br /> Management
            </h1>
            <p className="text-[#757095] font-sans">4 slots available</p>
          </div>
        </div>
        {/* content 1 */}
        <div className="mt-10 flex flex-col justify-center items-center space-y-8 md:grid md:grid-cols-2 md:space-y-0 md:gap-6 md:mx-auto md:justify-center md:items-center lg:max-w-xl xl:max-w-full xl:grid-cols-4 xl:mt-18 md:mt-16 ">
          {/* content 1 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 xl:px-6 hover:-translate-y-8 duration-1000 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <MdOutlineDesignServices className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Design & <br /> Creatives
            </h1>
            <p className="text-[#757095] font-sans">3 slots available</p>
          </div>
          {/* content 2 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <SiMarketo className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Marketing & <br /> Communications
            </h1>
            <p className="text-[#757095] font-sans">10 slots available</p>
          </div>
          {/* content 3 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6 h-64 ">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <GiGrowth className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Business <br /> Development
            </h1>
            <p className="text-[#757095] font-sans">1 slot available</p>
          </div>
          {/* content 4 */}
          <div className="bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 inline-block w-64 hover:-translate-y-8 duration-1000 xl:px-6  h-64">
            <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
              <MdOutlineSupportAgent className="text-2xl" />
            </div>
            <h1 className="text-2xl font-sans font-bold xl:text-2xl">
              Customer <br /> Support
            </h1>
            <p className="text-[#757095] font-sans">2 slots available</p>
          </div>
        </div>
      </div>
      {/* how to get started */}
      <div className="section-center ">
        {/* container */}
        <div className="mt-32 md:flex md:flex-row-reverse justify-center items-center gap-2 ">
          {/* image */}
          <div className="max-w-xs sm:max-w-sm md:max-w-full md:w-1/2 lg:max-w-full   ">
            <img
              src={car2}
              alt=""
              className="sm:w-96 md:w-full mx-auto xl:w-11/12"
            />
          </div>
          {/* text */}
          <div id="apply" className="mt-12 md:w-1/2 ">
            <h5 className="md:text-left ">Are you Interested in </h5>
            <h3 className=" text-3xl mt-3 md:text-left md:text-xl lg:text-3xl md:mt-1 ">
              Working with us ?
            </h3>
            <h5 className="w-96 md:w-80 mx-auto text-center text-[#1C1F37] font-alata text-md md:text-left md:mx-0 lg:w-full  xl:text-xl">
              Then follow these steps as stipulated below and begin your journey
              to financial freedom , job security and work fufillment
            </h5>
            {/* content1 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4">
              <div>
                <h3 className=" text-xl mt-3 md:text-left md:text-xl lg:text-2xl md:mt-1 ">
                  Identify your most qualified position
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  Go through our available positions and identify the position
                  well suited for the qualifications you have currently
                </h5>
              </div>
            </div>
            {/* content22 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div>
                <h3 className=" text-xl mt-3 md:text-left md:text-xl lg:text-2xl md:mt-1 ">
                  Send us a Mail
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  Send an email to <strong> contact@dimechest.com </strong>
                  indicating the position you applying for ,attach your cover
                  letter & resume. Dont forget to indicate the position
                </h5>
              </div>
            </div>
            {/* content33 */}
            <div className="mt-8 md:flex md:items-start md:justify-start gap-4 md:mt-2">
              <div>
                <h3 className=" text-xl mt-3 md:text-left md:text-xl lg:text-2xl md:mt-1 ">
                  Expect our feedback
                </h3>
                <h5 className="mt-2  md:mx-0 md:max-w-sm md:text-left md:text-sm ">
                  Upon successfully completion of the application form,expect a
                  feedback from our administrative staff
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Career;
